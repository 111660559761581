export const phoneValidator = (phone) =>
  /\+7\s\(7\d{2}\)\s\d{3}\-\d{2}\-\d{2}/.test(phone)

export function numberWithSpaces(x: number) {
  if (typeof x !== 'number') return x
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function debounce<T extends Function>(
  func: T,
  delay: number
): (...args: any[]) => void {
  let timeoutId: ReturnType<typeof setTimeout>

  return function _(this: any, ...args: any[]): void {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

export function scrollToId(id: string) {
  const el = document?.querySelector(id)
  el?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}
